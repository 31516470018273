import http from "../httpConfig";

class ApiService {
   getApis(params) {
      return http
         .get("/access_api", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getUserApis(params) {
      return http
         .get(`/access_api_user`, { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   buyApiAccess(api) {
      return http
         .post("/access_api", api)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   createApiAccess(api) {
      return http
         .post(`/access_api_create`, api)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateApiAccess(api) {
      return http
         .put(`/access_api/${api._id}`, api)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateApiAccessUser(api) {
      return http
         .put(`/access_api_user/${api._id}`, api)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteApiAccess(api) {
      return http
         .delete(`/access_api/${api._id}`, api)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new ApiService();
