import Vue from 'vue'
import App from '@/app/app.vue'
import store from '@/store'
import router from '@/router'
import VueI18n from 'vue-i18n'
import vSelect from 'vue-select'
import { ModalPlugin } from '@/plugins/modal'

import VueTelInput from 'vue-tel-input'
import BootstrapVue from 'bootstrap-vue'
import VeeValidate from 'vee-validate'
import VueToast from 'vue-toast-notification'
import setupInterceptors from './services/setupInterceptors'
import SearchableDropdown from './app/comps/SearchableDropdown.vue'

import 'bootstrap'
import './assets/sass/bootstrap/bootstrap.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import '@icon/themify-icons/themify-icons.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import 'vue-select/dist/vue-select.css'
import 'vue-tel-input/dist/vue-tel-input.css'

import './assets/css/spacing.css'
import './assets/sass/themes/custom.scss'


Vue.use(VueI18n)
  .use(VeeValidate)
  .use(VueTelInput)
  .use(BootstrapVue)
  .use(VueToast, { position: 'top' })

Vue.config.productionTip = false

Vue.component('searchable-dropdown', SearchableDropdown)
Vue.component('v-select', vSelect)

setupInterceptors(store)

// Load all locales and remember context
function loadMessages() {
  const context = require.context('./locales', true, /[a-z0-9-_]+\.json$/i)
  const messages = context
    .keys()
    .map((key) => ({ key, locale: key.match(/[a-z0-9-_]+/i)[0] }))
    .reduce(
      (messages, { key, locale }) => ({
        ...messages,
        [locale]: context(key),
      }),
      {}
    )
  
  return { context, messages }
}

const { context, messages } = loadMessages()

const i18n = new VueI18n({
  locale: 'en',
  messages,
})

const app = new Vue({
  i18n,
  store,
  router,
  template: '<App/>',
  components: { App },
})

Vue.use(ModalPlugin)

app.$mount('#app')

// Hot updates
if (module.hot) {
  module.hot.accept(context.id, () => {
    const { messages: newMessages } = loadMessages()
    
    Object.keys(newMessages)
      .filter((locale) => messages[locale] !== newMessages[locale])
      .forEach((locale) => {
        messages[locale] = newMessages[locale]
        i18n.setLocaleMessage(locale, messages[locale])
      })
  })
}
