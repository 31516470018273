<script>
export default {
  name: "ModalTitle"
}
</script>

<template>
  <h1 class="dp-modal-title">
    <slot></slot>
  </h1>
</template>