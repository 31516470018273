import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import { TokenService } from "../services";

Vue.use(Vuex);

let user = TokenService.getUser();
let loggedIn = !!user;

const store = new Vuex.Store({
   namespaced: true,
   state: {
      left_open: true,
      right_open: false,
      preloader: true,
      user: user,
      theme: {},
      settings: {},
      shop_settings: {},
      shopping_cart: {},
      loggedIn: loggedIn,
      pending_orders: 0,
      warning_orders: 0,
      is_admin_site: false,
      agent_pending_orders: 0,
      agent_warning_orders: 0,
      cancelled_orders: 0,
      pending_shop_orders: 0,
      my_incomplete_shop_orders: 0,
      pending_messages: 0,
      user_pending_orders: 0,
      pending_agent_orders_came_from_my_sub_portal: 0,
      user_complete_orders: 0,
      open_tickets: 0,
      route_name: "",
      checkout: {
         applied_coupon: {
            flat_amount: 0,
            percentage: 0,
         },
         shipping_method: {
            local_shipping_cost: 0,
            international_shipping_cost: 0,
            local_shipping_time: 0,
            international_shipping_time: 0,
         },
      },
      totalCost: 0
   },
   mutations: mutations,
   actions: actions,
});

export default store;
