<script>
export default {
  name: "ActionButton",
  props: {
    primary: {
      type: String,
      required: false
    },
    danger: {
      type: String,
      required: false
    },
    large: {
      type: String,
      required: false
    },
    medium: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    }
  },
  data() {
    return {
      rDisabled: false
    }
  },
  watch: {
    disabled(val) {
      this.rDisabled = val
    }
  }
}
</script>

<template>
  <button @click="$emit('click', $event)"
          :type="type"
          class="dp-modal-action-btn"
          :disabled="disabled"
          :class="{
            primary: primary !== undefined,
            danger: danger !== undefined,
            medium: medium !== undefined,
            large: large !== undefined
          }">
    <slot></slot>
  </button>
</template>