<script>
export default {
  name: "DpModal",
  props: {
    width: {
      type: String,
      required: false,
      default: ''
    },
    backdrop: {
      type: Boolean,
      required: false,
      default: true
    },
    closeOnOutsideClick: {
      type: Boolean,
      required: false,
      default: true
    },
    childComponent: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    events: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      opened: false,
      containerOpened: false,
      afterClosedCallback: null
    }
  },
  methods: {
    open() {
      this.containerOpened = true

      let time = setTimeout(() => {
        this.opened = true
        clearTimeout(time)
      }, 50)
    },
    afterClosed(callback) {
      this.afterClosedCallback = callback
    },
    close(data) {
      this.opened = false

      let time = setTimeout(() => {
        this.containerOpened = false

        this.$el.classList.remove('opened')
        this.$el.parentElement.removeChild(this.$el)
        this.$destroy()

        clearTimeout(time)
      }, 300)

      if (this.afterClosedCallback && typeof this.afterClosedCallback === 'function') {
        this.afterClosedCallback(data)
      }
    }
  }
}
</script>

<template>
  <div class="dp-modal-container" :class="{ opened: containerOpened === true }">
    <div class="dp-modal-backdrop"
         v-if="backdrop"
         :class="{ opened: opened === true }"
         @click.prevent="closeOnOutsideClick ? close(null) : null">
    </div>

    <div class="dp-modal" :style="{ width: width }" :class="{ opened: opened === true }">
      <component
          :is="childComponent"
          :data="data"
          @close="close">
      </component>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/common/theme.scss';

.dp-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0s;
  margin: 0;
  padding: 50px 0;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-height: 700px) {
    justify-content: flex-start;
  }

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }

  &.opened {
    opacity: 1;
    visibility: visible;
    z-index: 999999999;
  }

  .dp-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(0px);
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;

    &.opened {
      opacity: 1;
      visibility: visible;
      backdrop-filter: blur(5px);
    }
  }

  .dp-modal {
    width: 500px;
    max-width: calc(100% - 50px);
    height: auto;
    background: rgba($darkBg, 0.95);
    backdrop-filter: blur(5px);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    border: 1px solid darken($originalTextColor, 30%);
    border-radius: 10px;
    padding: 0;
    box-sizing: border-box;
    margin: 0 auto;
    transform: scale(0.95);
    opacity: 0;
    transition: 0.3s;

    &.opened {
      transform: scale(1);
      opacity: 1;
    }

    .dp-modal-title {
      font-size: 22px;
      color: #eee;
      margin: 0 0 15px 0;
      font-weight: 600;
      text-align: left;
    }

    .dp-modal-subtitle {
      font-size: 16px;
      color: #ccc;
      margin: 0 0 15px 0;
      font-weight: 500;
      text-align: left;
    }

    .dp-modal-action-btn {
      background: transparent;
      color: $gray400;
      padding: 0;
      border: none;
      outline: none;
      margin: 0;
      font-weight: 600;
      cursor: pointer;
      transition: 0.3s;
      box-shadow: none;

      &.medium {
        font-size: 16px;
      }

      &.large {
        font-size: 20px;
      }

      &:hover {
        color: darken($darkBg, 10%);
      }

      &.primary {
        color: $primary;

        &:hover {
          color: darken($primary, 10%);
        }
      }

      &.danger {
        color: #de0d0d;

        &:hover {
          color: darken(#de0d0d, 10%);
        }
      }

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
        color: #ddd !important;

        &:hover {
          color: #ddd !important;
        }
      }
    }

    .dp-modal-content {
      width: 100%;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    .dp-modal-footer {
      width: 100%;
      display: flex;
      flex-direction: row !important;
      justify-content: flex-start;
      padding: 0;
      box-sizing: border-box;
      align-items: flex-end;
      flex-flow: wrap;
      margin: 30px 0 0 0;

      .dp-modal-action-btn {
        margin-right: 15px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
</style>