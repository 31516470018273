import http from "../httpConfig";

class ECU_AdBLue_Locations_Service {
  async addLocation(model) {
    try {
      const formModel = new FormData()
      Object.entries(model).forEach(([name, value]) => {
        if (Array.isArray(model[name])) value.forEach(file => formModel.append(name, file))
        else formModel.append(name, model[name])
      })

      const res = await http
        .post("/vehicle/ecu-adblue-location", formModel);
      return await Promise.resolve(res.data);
    } catch (err) {
      return await Promise.reject(err);
    }
  }

  async updateLocation({ _id, ecu_location, ecu_location_images, adblue_location, adblue_location_images }) {
    try {
      const formModel = new FormData()
      formModel.append('_id', _id)
      formModel.append('ecu_location', ecu_location)
      formModel.append('adblue_location', adblue_location)
      ecu_location_images.forEach(file => formModel.append('ecu_location_images', file))
      adblue_location_images.forEach(file => formModel.append('adblue_location_images', file))

      const res = await http
        .put("/vehicle/ecu-adblue-location/" + _id, formModel);
      return await Promise.resolve(res.data);
    } catch (err) {
      return await Promise.reject(err);
    }
  }

  async getAllLocations(params) {
    try {
      const res = await http
        .get("/vehicle/ecu-adblue-locations", { params: params });
      return await Promise.resolve(res.data);
    } catch (err) {
      return await Promise.reject(err);
    }
  }

  async getOneLocation({ vtype, manufacture, vmodel, generation, variant }) {
    try {
      const res = await http
        .get(`/vehicle/one-ecu-adblue-location`, {
          params: { vtype, manufacture, vmodel, generation, variant }
        });
      return await Promise.resolve(res.data);
    } catch (err) {
      return await Promise.reject(err);
    }
  }

  /**
   * Deletes a location by ID.
   *
   * @param {number} id - The ID of the location to delete.
   * @return {Promise} A promise that resolves with the deleted data if successful, or rejects with an error if not.
   */
  async deleteLocation(id) {
    try {
      const res = await http
        .delete(`/vehicle/ecu-adblue-location/${id}`);
      return await Promise.resolve(res.data);
    } catch (err) {
      return await Promise.reject(err);
    }
  }

}

export default new ECU_AdBLue_Locations_Service();
