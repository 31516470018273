import http from "../httpConfig";

class FileLinkService {
   createFileLink(model, onUploadProgress) {
      const config = {
         headers: {
            "Content-Type": "multipart/form-data",
         },
         onUploadProgress,
      };
      return http
         .post("/flink", model, config)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateFileLink(_id, model, onUploadProgress) {
      const config = {
         headers: {
            "Content-Type": "multipart/form-data",
         },
         onUploadProgress,
      };
      return http
         .put(`/flink/${_id}`, model, config)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getFileLinks(params) {
      return http
         .get("/flink", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getFileLinksAll(params) {
      return http
         .get("/flink_all", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getFileLinkCategories() {
      return http
         .get("/flink_categories")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteFileLink(model) {
      return http
         .delete(`/flink/${model._id}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   downloadFile(flink) {
      return http
         .get(`/flink/${flink._id}/download`, { responseType: "blob" })
         .then((res) => {
            this.download(res.data, flink.name, flink.link);
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   download(data, file_name, file_link) {
      let blob = new Blob([data], {
         type: "application/octet-stream",
      });
      let downloadElement = document.createElement("a");
      let href = window.URL.createObjectURL(blob);
      let ext = file_link.split(".").pop();
      downloadElement.href = href;
      downloadElement.download = file_name + "." + ext;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
   }
}

export default new FileLinkService();
