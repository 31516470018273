import store from '@/store'

const user_routes = [
  {
    path: "",
    name: "statistics",
    component: () => import("../app/users/statistics/statistics.vue"),
    meta: {
      title: "Statistics",
    }
  },
  {
    path: "submit_order",
    name: "submit_order",
    component: () => import("../app/users/SubmitNew.vue"),
    meta: {
      title: "Submit New",
    },
  },
  {
    path: "your_order",
    name: "your_order",
    component: () => import("../app/users/YourOrders.vue"),
    meta: {
      title: "Your Order ",
    },
  },
  {
    path: "your-agent-orders",
    name: "your_agent_orders",
    component: () => import("../app/users/YourAgentOrders.vue"),
    meta: {
      title: "Your Agent Orders",
    },
  },
  {
    path: "order_details/:id",
    name: "order_details",
    component: () => import("../app/users/OrderDetails.vue"),
    meta: {
      title: "Order Details",
    },
  },
  {
    path: "agent-order-details/:id",
    name: "agent_order_details",
    component: () => import("../app/users/AgentOrderDetails.vue"),
    meta: {
      title: "Agent Order Details",
    },
  },
  {
    path: "buy_credits",
    name: "buy_credits",
    component: () => import("../app/users/buy-credits/buy-credits.vue"),
    meta: {
      title: "Buy Credits",
    },
  },
  {
    path: "shop",
    name: "shop",
    component: () => import("../app/users/shop/ShopHome.vue"),
    meta: {
      title: "Home | Shop",
    },
  },
  {
    path: "shop/cart",
    name: "shopping_cart",
    component: () => import("../app/users/shop/ShoppingCart.vue"),
    meta: {
      title: "Cart | Shop",
    },
  },
  {
    path: "shop/product/:slug",
    name: "product",
    component: () => import("../app/users/shop/ViewProduct.vue"),
  },
  {
    path: "shop/orders",
    name: "shop_orders",
    component: () => import("../app/users/shop/OrderList.vue"),
    meta: {
      title: "Orders | Shop",
    },
  },
  {
    path: "shop/order/:invoice_no",
    name: "view_order",
    component: () => import("../app/users/shop/ViewOrder.vue"),
    meta: {
      title: 'Order | Shop'
    }
  },
  {
    path: "shop/checkout",
    name: "checkout",
    component: () => import("../app/users/shop/CheckoutPage.vue"),
    meta: {
      title: "Checkout | Shop",
    }
  },
  {
    path: "shop/checkout/:status/:invoice_no?",
    name: "checkout_success",
    component: () => import("../app/users/shop/CheckoutStatus.vue"),
    meta: {
      title: "Checkout status | Shop",
    }
  },
  {
    path: "shop/terms-and-conditions",
    name: "terms_and_conditions",
    component: () => import("../app/users/shop/TermsAndConditions.vue"),
    meta: {
      title: "Terms & Conditions | Shop",
    }
  },
  {
    path: "vehicle_statistics",
    name: "vehicle_statistics",
    props: true,
    component: () => import("../app/users/vehicle-statistics/vehicle-statistics.vue"),
    meta: {
      title: "Vehicle Statistics",
    },
  },
  {
    path: "ecu-adblue-locations",
    name: "ecu-adblue-locations",
    props: true,
    component: () => import("../app/users/EcuAdblueLocations.vue"),
    meta: {
      title: "ECU & Adblue Locations",
    },
  },
  {
    path: "eco_calculator",
    name: "eco_calculator",
    props: true,
    component: () => import("../app/users/EcoCalculator.vue"),
    meta: {
      title: "ECO Calculator",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.eco_calculator_public) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "access_api",
    name: "access_api",
    component: () => import("../app/users/api-access/api-access.vue"),
    meta: {
      title: "Api Access",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.api_access) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "p_codes_bosch_codes",
    name: "p_codes_bosch_codes",
    component: () => import("../app/users/code-services/code-services.vue"),
    meta: {
      title: "P Codes/ Bosch Codes",
    },
    // beforeEnter: async (to, from, next) => {
    //   if (store.state.settings.codes_access) {
    //     next();
    //   } else {
    //     next("/");
    //   }
    // }
  },
  {
    path: "file_upload",
    name: "file_upload",
    component: () => import("../app/users/FileUpload.vue"),
    meta: {
      title: "File Upload",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.file_upload_public) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "terms_and_conditions",
    name: "terms_and_conditions",
    component: () => import("../app/users/TermsAndConditions.vue"),
    meta: {
      title: "Terms And Conditions",
    },
  },
  {
    path: "chat_support",
    name: "chat_support",
    component: () => import("../app/users/chat-support/chat-support.vue"),
    meta: {
      title: "Chat Support",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.live_support_public) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "open_ticket",
    name: "open_ticket",
    component: () => import("../app/users/OpenTicket.vue"),
    meta: {
      title: "Open Ticket",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.ticket_system_public) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "my_tickets",
    name: "my_tickets",
    component: () => import("../app/users/MyTickets.vue"),
    meta: {
      title: "My Ticket"
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.ticket_system_public) {
        next()
      } else {
        next("/")
      }
    }
  },
  {
    path: "ticket_details/:id",
    name: "ticket_details",
    component: () => import("../app/users/TicketDetails.vue"),
    meta: {
      title: "Ticket Details"
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.ticket_system_public) {
        next()
      } else {
        next("/")
      }
    }
  },
  {
    path: "profile_settings",
    name: "profile_settings",
    component: () => import("@/app/users/ProfileSettings.vue"),
    meta: {
      title: "Profile Settings"
    }
  }
]

export default user_routes
