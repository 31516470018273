import DpModal from './DpModal.vue'
import ModalTitle from './comps/ModalTitle.vue'
import ModalSubtitle from './comps/ModalSubtitle.vue'
import ModalFooter from './comps/ModalFooter.vue'
import ActionButton from './comps/ActionButton.vue'
import ModalContent from './comps/ModalContent.vue'
import VueI18n from "vue-i18n";

export const ModalPlugin = {
  install(Vue) {
    
    Vue.component('modal-title', ModalTitle)
    Vue.component('modal-subtitle', ModalSubtitle)
    Vue.component('modal-content', ModalContent)
    Vue.component('modal-footer', ModalFooter)
    Vue.component('action-button', ActionButton)
    
    Vue.prototype.$modal = {
      open(component, config) {
        
        let dpModal = null
        
        Vue.use(VueI18n)
        
        function loadMessages() {
          const context = require.context('@/locales', true, /[a-z0-9-_]+\.json$/i)
          const messages = context
            .keys()
            .map((key) => ({ key, locale: key.match(/[a-z0-9-_]+/i)[0] }))
            .reduce(
              (messages, { key, locale }) => ({
                ...messages,
                [locale]: context(key),
              }),
              {}
            )
          
          return { context, messages }
        }
        
        const { messages } = loadMessages()
        
        const i18n = new VueI18n({
          locale: 'en',
          messages,
        })
        
        const modalInstance = new Vue({
          i18n,
          render: (h) => {
            return h(DpModal, {
              props: {
                width: config.width || '',
                backdrop: config.backdrop === undefined ? true : config.backdrop === true,
                closeOnOutsideClick: config.closeOnOutsideClick === undefined ? true : config.closeOnOutsideClick === true,
                childComponent: component,
                data: config && config.data ? { ...config.data } : {}
              }
            })
          },
          mounted() {
            dpModal = this.$children[0]
            dpModal.open()
          }
        })
        
        modalInstance.$on('close', () => {
          modalInstance.$destroy()
        })
        
        modalInstance.$mount()
        document.body.appendChild(modalInstance.$el)
        
        return dpModal ? { afterClosed: dpModal.afterClosed } : { afterClosed: (callback) => {} }
      }
    }
  }
}