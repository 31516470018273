import http from "../httpConfig";

class ShopSettingsService {
   get() {
      return http.get("/shop/settings/get")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }


   get_statistics() {
      return http.get("/shop/settings/get_statistics")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   update({
       status,
       description,
       terms_and_conditions,
       paypal_payment_enabled,
       payfast_payment_enabled,
       stripe_payment_enabled,
       crypto_payment_enabled,
       square_payment_enabled,
       bank_payment_enabled
   }) {
      return http.post("/shop/settings/update", {
         status,
         description,
         terms_and_conditions,
         paypal_payment_enabled,
         payfast_payment_enabled,
         stripe_payment_enabled,
         crypto_payment_enabled,
         square_payment_enabled,
         bank_payment_enabled
      })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new ShopSettingsService();
