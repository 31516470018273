<script>
export default {
  name: "ModalContent"
}
</script>

<template>
  <div class="dp-modal-content">
    <slot></slot>
  </div>
</template>

<style scoped>

</style>