<script>
export default {
  name: "ModalSubtitle"
}
</script>

<template>
  <h3 class="dp-modal-subtitle">
    <slot></slot>
  </h3>
</template>