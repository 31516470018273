import http from "../httpConfig";

class PricesService {
   async createPrice(price) {
      await http
         .post("/price", price)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   async updatePrice(price) {
      await http
         .put(`/price/${price._id}`, price)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   async updatePricePos(data) {
      await http
         .put(`/price/${data._id}/update_pos`, data)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getPrices(params) {
      return http
         .get("/price", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getAllPrices() {
      return http
         .get("/price_all")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deletePrice(price) {
      return http
         .delete(`/price/${price._id}`, price)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new PricesService();
