<script>
export default {
  name: 'SearchableDropdown',
  props: {
    placeholder: {
      type: String,
      required: false,
      default: () => 'Please select an option'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    name: {
      type: String,
      required: false,
      default: () => ''
    },
    maxItem: {
      type: Number,
      required: false,
      default: () => 10
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      typingCheckerTimeout: 0,
      placeholder_reactive: '',
      disabled_reactive: false,
      name_reactive: '',
      searchedText: '',
      maxItem_reactive: 10,
      selectedOption: { id: '', name: '', value: null },
      filteredOptions: [],
      showDropdownItems: false
    }
  },
  created() {

    this.placeholder_reactive = this.$props.placeholder
    this.disabled_reactive = this.$props.disabled
    this.name_reactive = this.$props.name
    this.maxItem_reactive = this.$props.maxItem

    this.searchedText = this.selectedOption.name
    this.filteredOptions = [
      { id: '', name: '', value: null },
      ...this.$props.options
    ]

    if (this.filteredOptions.length > 0) {
      this.selected(this.filteredOptions[0])
    } else {
      this.selected({ id: '', name: '', value: null })
    }

  },
  methods: {
    filterDropdownItems() {

      if (this.typingCheckerTimeout !== 0) clearTimeout(this.typingCheckerTimeout)

      this.typingCheckerTimeout = setTimeout(() => {

        if (this.searchedText) {

          let filteredOptions = [
            { id: '', name: '', value: null }
          ]

          for (let op of this.$props.options) {
            try {
              if (op && op.name.toLowerCase().search(this.searchedText.toLowerCase().toString()) > -1 && filteredOptions.length <= this.maxItem_reactive) {
                filteredOptions.push(op)
              }
            } catch (e) {
              // test
            }
          }

          this.filteredOptions = filteredOptions

        } else {
          this.filteredOptions = [
            { id: '', name: '', value: null },
            ...this.$props.options.slice(0, this.maxItem_reactive)
          ]
        }

      }, 200)

    },
    change() {
      if (!this.searchedText || this.searchedText === '' || this.searchedText.trim() === '') {
        if (this.selectedOption.name) {
          this.searchedText = this.selectedOption.name
        } else {
          this.searchedText = ''
        }

        this.filteredOptions = [
          { id: '', name: '', value: null },
          ...this.$options.slice(0, this.maxItem_reactive)
        ]
      }
    },
    selected(selectedOption) {
      this.selectedOption = selectedOption
      this.searchedText = selectedOption.name || ''
      this.$emit('selected', selectedOption)
    }
  },
  watch: {
    options(options) {

      if (options && options.length > -1) {
        this.filteredOptions = [
          { id: '', name: '', value: null },
          ...options.slice(0, this.maxItem_reactive)
        ]
      } else {
        this.filteredOptions = [
          { id: '', name: '', value: null }
        ]
      }

      if (this.filteredOptions[0] && this.filteredOptions[0].id !== this.selectedOption.id) {
        this.selected(this.filteredOptions[0])
      }
    },

    placeholder(value) {
      this.placeholder_reactive = value
    },

    disabled(value) {
      this.disabled_reactive = value
    },

    name(value) {
      this.name_reactive = value
    },

    maxItem(value) {
      this.maxItem_reactive = value
    }
  },
  computed: {
    hasOptions() {
      return this.filteredOptions.length > 0
    },
    allOkay() {
      return (this.showDropdownItems && !this.disabled_reactive)
    }
  }
}
</script>

<style lang="scss">
.nhr-searchable-dropdown {
  max-width: 100% !important;
  width: fit-content !important;
  box-sizing: border-box !important;
  margin: 0;
  padding: 0 !important;
  position: relative !important;
  font-size: 14px !important;
  font-weight: normal;
  text-transform: none !important;

  .search-field-container {
    width: 100% !important;
    box-sizing: border-box !important;
    padding: 5px 8px !important;
    background: #333 !important;
    border-radius: 5px !important;
    border: 1px solid #eee !important;
    height: 40px !important;
    transition: 0.3s !important;
    position: relative;

    .search-field {
      width: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
      overflow: hidden !important;
      border: none !important;
      background: #333 !important;
      outline: none !important;
      box-shadow: none !important;
      height: 100% !important;
      font-size: inherit !important;
      color: #eee !important;
    }

  }

  .search-field-container.disabled {
    border-color: #888 !important;
    cursor: not-allowed;
    opacity: 0.8 !important;

    .search-field:disabled {
      cursor: not-allowed !important;
    }
  }

  .search-field-container.show-dropdown-items {
    border-radius: 4px 4px 0 0 !important;
  }

  .dropdown-items-container {
    position: absolute !important;
    top: 41px !important;
    background: #111 !important;
    border: 1px solid #666;
    width: 100% !important;
    height: auto !important;
    max-height: 500px !important;
    padding: 0 !important;
    margin: 0 !important;
    box-sizing: border-box !important;
    border-radius: 0 0 4px 4px !important;
    overflow: hidden !important;
    overflow-y: scroll !important;
    z-index: 9;
    box-shadow: 2px 1px 4px 0.5px rgba(0, 0, 0, 0.2);

    ul {
      list-style: none !important;
      padding: 0 !important;
      margin: 0 !important;
      box-sizing: border-box !important;
      width: 100% !important;
      height: auto !important;

      li {
        padding: 1.5px 7px !important;
        box-sizing: border-box !important;
        display: block !important;
        width: 100% !important;
        text-align: left !important;
        font-size: inherit !important;
        transition: 0.3s !important;
        cursor: pointer !important;
      }

      li:hover,
      li.selected:hover {
        background: #444 !important;
        color: #eee !important;
      }

      li.selected {
        background: #222 !important;
      }
    }
  }

  .dropdown-items-container.hide {
    opacity: 0;
    visibility: hidden;
  }
}
</style>

<template>
  <div class="nhr-searchable-dropdown" :style="$props.style">
    <div class="search-field-container"
      :class="{
        'show-dropdown-items': showDropdownItems && (filteredOptions.length > 0),
        disabled: disabled_reactive
      }">
      <input type="search" autocomplete="off"
        class="search-field"
        :disabled="disabled_reactive"
        :name="name_reactive"
        :placeholder="placeholder_reactive"
        @change="change"
        @keyup="filterDropdownItems"
        @focus="showDropdownItems = true"
        @blur="showDropdownItems = false"
        v-model="searchedText">
    </div>

    <div class="dropdown-items-container" :class="{ hide: !allOkay }">
      <ul v-if="hasOptions">
        <li v-for="option of filteredOptions.slice(0, maxItem_reactive)" :key="option.id" @click="selected(option)"
          :class="{ selected: option.id === selectedOption.id }">
          {{ option.name === '' ? placeholder_reactive : option.name }}
        </li>
      </ul>

      <ul v-else>
        <li class="disabled">No options</li>
      </ul>
    </div>
  </div>
</template>
