import { render, staticRenderFns } from "./ModalContent.vue?vue&type=template&id=2ff4700b&scoped=true"
import script from "./ModalContent.vue?vue&type=script&lang=js"
export * from "./ModalContent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ff4700b",
  null
  
)

export default component.exports