import http from "../httpConfig"

class OrderService {
  async createOrder(order, onUploadProgress) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    }
    
    return http
      .post("/order", order, config)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  retryAutomation(id) {
    return http
      .post(`/order/${id}/automation_retry`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  getOrders(keywords, status, page, size) {
    return http
      .get("/order", {
        params: {
          keywords,
          status,
          page,
          size,
        },
      })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  getOrder(order_id) {
    return http
      .get(`/order/${order_id}`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  getUserOrders(params) {
    return http
      .get(`/user_orders`, { params: params })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  getPendingOrders(params) {
    return http
      .get("/pending_orders", { params: params })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  getCompleteOrders(keyword, status, page, size) {
    return http
      .get("/complete_orders", {
        params: {
          keyword,
          status,
          page,
          size,
        },
      })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  getWarningOrdersCount() {
    return http
      .get("/warning_orders_count")
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  getCancelledOrdersCount() {
    return http
      .get("/cancelled_orders_count")
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  deleteOrder(order) {
    return http
      .delete(`/order/${order._id}`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  submitOrder(id, order, onUploadProgress) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    }
    return http
      .put(`/order/${id}/submit_order`, order, config)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  cancelOrder(id) {
    return http
      .put(`/order/${id}/cancel_order`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  changeOrderStatus(id, data) {
    return http
      .put(`/order/${id}/change_status`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  downloadOldFile(order) {
    return http
      .get(`/order/${order._id}/download_old_file`, { responseType: "blob" })
      .then((res) => {
        this.download(res.data, order.original_file, order.reg_no)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  downloadNewFile(order) {
    return http
      .get(`/order/${order._id}/download_new_file`, { responseType: "blob" })
      .then((res) => {
        this.download(res.data, order.modified_file, order.reg_no)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  getPendingOrdersCount() {
    return http
      .get("/pending_orders_count")
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  // to get the count of agent orders that came from the dealer's sub portal
  getUserPendingOrdersCount() {
    return http
      .get("/user_pending_orders_count")
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  getUserCompleteOrdersCount() {
    return http
      .get("/user_complete_orders_count")
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  getCompleteOrdersCount() {
    return http
      .get("/complete_orders_count")
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  download(data, file_name, reg_no) {
    let blob = new Blob([data], {
      type: "application/octet-stream",
    })
    let downloadElement = document.createElement("a")
    let href = window.URL.createObjectURL(blob)
    let ext = file_name.split(".").pop()
    downloadElement.href = href
    let originalFileName = file_name.split("__fn__")[1] || ""
    downloadElement.download =
      (ext.toLowerCase() === "zip" ? "" : originalFileName.replace("." + ext, "") + "_") + reg_no + "." + ext
    document.body.appendChild(downloadElement)
    downloadElement.click()
    document.body.removeChild(downloadElement)
    window.URL.revokeObjectURL(href)
  }
}

export default new OrderService()
