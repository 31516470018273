import http from "../httpConfig";

class OpensService {
   getOpenTimes() {
      return http
         .get("/open_times")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateOpenTimes(opens) {
      return http
         .post("/open_times", opens)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getCloseDates() {
      return http
         .get("/close_dates")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateCloseDates(data) {
      return http
         .post("/close_dates", data)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new OpensService();
