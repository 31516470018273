import http from "./httpConfig";
import { TokenService } from "./";

const setup = (store) => {
   http.interceptors.request.use(
      (config) => {
         const token = TokenService.getLocalAccessToken();
         if (token) {
            config.headers["x-access-token"] = token;
         }
         return config;
      },
      (err) => {
         return Promise.reject(err);
      }
   );

   http.interceptors.response.use(
      (res) => {
         return res;
      },
      async (err) => {
         const originalConfig = err.config;

         if (originalConfig.url !== "/auth/signin" && err.response) {
            if (err.response.status === 401 && !originalConfig._retry) {
               originalConfig._retry = true;

               try {
                  const rs = await http.post("/auth/refresh_token", {
                     refresh_token: TokenService.getLocalRefreshToken(),
                  });

                  const { access_token } = rs.data;

                  store.commit("refreshToken", access_token);
                  TokenService.updateLocalAccessToken(access_token);

                  return http(originalConfig);
               } catch (_err) {
                  store.commit("logout");
                  return Promise.reject(_err);
               }
            }
         }
         return Promise.reject(err);
      }
   );
};

export default setup;
