import http from "../httpConfig";

class CategoryService {

   get_by_id(id) {
      return http.get("/shop/category/get_by_id/" + id)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }


   get_by_slug(slug) {
      return http.get("/shop/category/get_by_slug/" + slug)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }


   get_all({ name, page, size }) {
      return http.get(`/shop/category/get_all?name=${name}&page=${page}&size=${size}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   add({ name, slug }) {
      return http.post("/shop/category/add", { name, slug })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   update({ _id: id, name, slug }) {
      return http.post("/shop/category/update", { id, name, slug })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   delete({ id }) {
      return http.post("/shop/category/delete", { id })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new CategoryService();
