import http from "../httpConfig";

class ManufactureService {
   createManufacture(model) {
      return http
         .post("/vehicle/manufacture", model)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateManufacture(model) {
      return http
         .put(`/vehicle/manufacture/${model._id}`, model)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getManufactures(params) {
      return http
         .get("/vehicle/manufacture", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteManufacture(model) {
      return http
         .delete(`/vehicle/manufacture/${model._id}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   selectedManufactures(params) {
      return http
         .get("/vehicle/manufacture/selected", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new ManufactureService();
