import http from "../httpConfig";

class ProductService {

   get_by_slug(slug) {
      return http.get("/shop/product/get_by_slug/" + slug)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   get_by_product_code(product_code) {
      return http.get("/shop/product/get_by_product_code/" + product_code)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   get_by_id(id) {
      return http.get("/shop/product/get_by_id/" + id)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   get_all({ name, category_id, status, sort_by, sort_order, page, size }) {
      return http.get(`shop/product/get_all?name=${name}&category_id=${category_id}&status=${status}&sort_by=${sort_by}&sort_order=${sort_order}&page=${page}&size=${size}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   add({ name, slug, image1, image2, image3, price, weight, weight_unit, short_description, description, product_code, category_id, extra_options, inventory, status }) {

      const data = new FormData()
      data.append('name', name)
      data.append('image1', image1)
      data.append('image2', image2)
      data.append('image3', image3)
      data.append('price', price)
      data.append('weight', weight)
      data.append('weight_unit', weight_unit)
      data.append('short_description', short_description)
      data.append('description', description)
      data.append('slug', slug)
      data.append('product_code', product_code)
      data.append('category_id', category_id)
      data.append('extra_options', JSON.stringify(extra_options))
      data.append('status', status)
      data.append('inventory', inventory)

      return http.post("/shop/product/add", data)
        .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   update_status({ id, status }) {
      return http.post("/shop/product/update_status", { id, status })
        .then((res) => {
            return Promise.resolve(res.data);
         })
        .catch((err) => {
           return Promise.reject(err);
        });
   }

   update({ _id, name, image1, image2, image3, price, weight, weight_unit, slug, short_description, description, status, product_code, category_id, extra_options, inventory }) {

      const data = new FormData()
      data.append('id', _id)
      data.append('name', name)
      data.append('image1', image1)
      data.append('image2', image2)
      data.append('image3', image3)
      data.append('price', price)
      data.append('weight', weight)
      data.append('weight_unit', weight_unit)
      data.append('short_description', short_description)
      data.append('description', description)
      data.append('slug', slug)
      data.append('product_code', product_code)
      data.append('category_id', category_id)
      data.append('extra_options', JSON.stringify(extra_options))
      data.append('status', status)
      data.append('inventory', inventory)

      return http.post("/shop/product/update", data)
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
           return Promise.reject(err);
        });
   }

   delete({ id }) {
      return http.post("/shop/product/delete", { id })
        .then((res) => {
            return Promise.resolve(res.data);
         })
        .catch((err) => {
           return Promise.reject(err);
        });
   }

}

export default new ProductService();
