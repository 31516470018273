import http from "../httpConfig";

class MessageService {
   uploadMessageImage(data) {
      const config = {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      };
      return http
         .post("/upload_message_image", data, config)
         .then((res) => {
            return Promise.resolve(res);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   removeMessageImage(data) {
      return http
         .post("/remove_message_image", data)
         .then((res) => {
            return Promise.resolve(res);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   sendMessage(message) {
      return http
         .post("/message", message)
         .then((res) => {
            return Promise.resolve(res);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getMessages(params) {
      return http
         .get("/messages", { params: params })
         .then((res) => {
            return Promise.resolve(res);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getAllMessages(params) {
      return http
         .get("/all_messages", { params: params })
         .then((res) => {
            return Promise.resolve(res);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteMessage(data) {
      return http
         .delete(`/message/${data._id}`)
         .then((res) => {
            return Promise.resolve(res);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new MessageService();
