import http from "../httpConfig";

class OptionsService {
   async createOption(option) {
      await http
         .post("/option", option)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   async updateOption(option) {
      await http
         .put(`/option/${option._id}`, option)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   async updateOptionPos(data) {
      await http
         .put(`/option/${data._id}/update_pos`, data)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getOptions(params) {
      return http
         .get("/option", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getAllOptions() {
      return http
         .get("/option_all")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteOption(option) {
      return http
         .delete(`/option/${option._id}`, option)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new OptionsService();
