<script>
export default {
  name: "ModalFooter"
}
</script>

<template>
  <div class="dp-modal-footer">
    <slot></slot>
  </div>
</template>