import store from "../store";

const admin_routes = [
   {
      path: "",
      component: () => import("../app/admin/Dashboard.vue"),
      meta: {
         title: "Dashboard",
      },
   },
   {
      path: "website_settings",
      component: () => import("../app/admin/site_settings/SettingsMain.vue"),
      meta: {
         title: "Website Settings",
      },
   },
   {
      path: "manage_orders/pending_orders",
      name: "manage_orders.pending_orders",
      component: () => import("../app/admin/manage_orders/PendingOrders.vue"),
      meta: {
         title: "Pending Orders",
      },
   },
   {
      path: "manage_orders/order_history",
      name: "manage_orders.order_history",
      component: () => import("../app/admin/manage_orders/OrderHistory.vue"),
      meta: {
         title: "Order History",
      },
   },
   {
      path: "manage_orders/order_details/:id",
      name: "manage_orders.order_details",
      component: () => import("../app/admin/manage_orders/OrderDetails.vue"),
      meta: {
         title: "Order Details",
      },
   },
   {
      path: "manage_agent_orders/pending_orders",
      name: "manage_agent_orders.pending_orders",
      component: () => import("../app/admin/manage_agent_orders/PendingOrders.vue"),
      meta: {
         title: "Pending Orders",
      },
      beforeEnter: async (to, from, next) => {
         if (store.state.settings.is_admin_site) {
            next();
         } else {
            next("/admin");
         }
      },
   },
   {
      path: "manage_agent_orders/order_history",
      name: "manage_agent_orders.order_history",
      component: () => import("../app/admin/manage_agent_orders/OrderHistory.vue"),
      meta: {
         title: "Order History",
      },
      beforeEnter: async (to, from, next) => {
         if (store.state.settings.is_admin_site) {
            next();
         } else {
            next("/admin");
         }
      },
   },
   {
      path: "manage_agent_orders/order_details/:id",
      name: "manage_agent_orders.order_details",
      component: () => import("../app/admin/manage_agent_orders/OrderDetails.vue"),
      meta: {
         title: "Order Details",
      },
      beforeEnter: async (to, from, next) => {
         if (store.state.settings.is_admin_site) {
            next();
         } else {
            next("/admin");
         }
      },
   },
   {
      path: "agent_manager/agency_invoices",
      component: () => import("../app/admin/agent_manager/AgencyInvoices.vue"),
      meta: {
         title: "Agency Invoices",
      },
   },
   {
      path: "agent_manager/agent_balance",
      component: () => import("../app/admin/agent_manager/AgentBalance.vue"),
      meta: {
         title: "Agent Balance",
      },
   },
   {
      path: "agent_manager/agent_bulk_email",
      component: () => import("../app/admin/agent_manager/AgentBulkEmail.vue"),
      meta: {
         title: "Agent Bulk Email",
      },
   },
   {
      path: "agent_manager/agents_list",
      component: () => import("../app/admin/agent_manager/AgentsList.vue"),
      meta: {
         title: "Agents List",
      },
   },
   {
      path: "vehicle_statistics/variant",
      component: () => import("../app/admin/vehicle_statistics/Variant.vue"),
      meta: {
         title: "Variants",
      },
   },
   {
      path: "vehicle_statistics/manufacture",
      component: () => import("../app/admin/vehicle_statistics/Manufacture.vue"),
      meta: {
         title: "Manufactures",
      },
   },
   {
      path: "vehicle_statistics/generation",
      component: () => import("../app/admin/vehicle_statistics/Generation.vue"),
      meta: {
         title: "Generations",
      },
   },
   {
      path: "vehicle_statistics/model",
      component: () => import("../app/admin/vehicle_statistics/VModel.vue"),
      meta: {
         title: "Models",
      },
   },
   {
      path: "vehicle_statistics/type",
      component: () => import("../app/admin/vehicle_statistics/VType.vue"),
      meta: {
         title: "Vehicle Types",
      },
   },
   {
      path: "vehicle_statistics",
      component: () => import("../app/admin/vehicle_statistics/VehicleStatistics.vue"),
      meta: {
         title: "Vehicle Statistics",
      },
   },
   {
      path: "tuning_service/services",
      component: () => import("../app/admin/tuning_service/Services.vue"),
      meta: {
         title: "Services",
      },
   },
   {
      path: "tuning_service/options",
      component: () => import("../app/admin/tuning_service/Options.vue"),
      meta: {
         title: "Options",
      },
   },
   {
      path: "access_api",
      component: () => import("../app/admin/api_manager/ApiAccess.vue"),
      meta: {
         title: "Api Access",
      },
   },
   {
      path: "shop_manager/statistics",
      component: () => import("../app/admin/shop_manager/Statistics.vue"),
      meta: {
         title: "Shop Statistics",
      },
   },
   {
      path: "shop_manager/settings",
      component: () => import("../app/admin/shop_manager/ShopSettings.vue"),
      meta: {
         title: "Shop Settings",
      },
   },
   {
      path: "shop_manager/orders",
      component: () => import("../app/admin/shop_manager/OrderList.vue"),
      meta: {
         title: "Orders",
      },
   },
   {
      path: "shop_manager/order/:order_id",
      name: "view_order",
      component: () => import("../app/admin/shop_manager/ViewOrder.vue"),
      meta: {
         title: "Order | Shop",
      },
   },
   {
      path: "shop_manager/products",
      name: "product_list",
      component: () => import("../app/admin/shop_manager/ProductList.vue"),
      meta: {
         title: "Products",
      },
   },
   {
      path: "shop_manager/categories",
      component: () => import("../app/admin/shop_manager/CategoryList.vue"),
      meta: {
         title: "Categories",
      },
   },
   {
      path: "shop_manager/coupons",
      component: () => import("../app/admin/shop_manager/CouponList.vue"),
      meta: {
         title: "Coupons",
      },
   },
   {
      path: "shop_manager/shipping_methods",
      component: () => import("../app/admin/shop_manager/ShippingMethods.vue"),
      meta: {
         title: "Shipping Methods",
      },
   },
   {
      path: "ecu-and-adblue-locations",
      component: () => import("../app/admin/miscellanceous/ECU_AdBlueLocations.vue"),
      meta: {
         title: "ECU & AdBlue Locations",
      },
   },
   {
      path: "bosch_codes",
      component: () => import("../app/admin/miscellanceous/BoschCode.vue"),
      meta: {
         title: "Bosch Codes",
      },
   },
   {
      path: "p_codes",
      component: () => import("../app/admin/miscellanceous/PCode.vue"),
      meta: {
         title: "P Codes",
      },
   },
   {
      path: "df_to_p_codes",
      component: () => import("../app/admin/miscellanceous/DFtoPCode.vue"),
      meta: {
         title: "DF to P Codes",
      },
   },
   {
      path: "downloads",
      component: () => import("../app/admin/miscellanceous/FileLink.vue"),
      meta: {
         title: "Downloads",
      },
   },
   {
      path: "terms_and_conditions",
      component: () => import("../app/admin/TermsAndConditions.vue"),
      meta: {
         title: "Terms And Conditions",
      },
   },
   {
      path: "chat_support",
      component: () => import("../app/admin/ChatSupport.vue"),
      meta: {
         title: "Chat Support",
      },
      beforeEnter: async (to, from, next) => {
         if (store.state.settings.live_support_public) {
            next();
         } else {
            next("/admin");
         }
      },
   },
   {
      path: "all_tickets",
      component: () => import("../app/admin/ticket_service/AllTickets.vue"),
      meta: {
         title: "All Tickets",
      },
      beforeEnter: async (to, from, next) => {
         if (store.state.settings.ticket_system_public) {
            next();
         } else {
            next("/admin");
         }
      },
   },
   {
      path: "ticket_details/:id",
      component: () => import("../app/admin/ticket_service/TicketDetails.vue"),
      meta: {
         title: "Ticket Details",
      },
      beforeEnter: async (to, from, next) => {
         if (store.state.settings.ticket_system_public) {
            next();
         } else {
            next("/admin");
         }
      },
   },
   {
      path: "user_profile",
      component: () => import("../app/admin/UserProfile.vue"),
      meta: {
         title: "User Profile",
      },
   },
   {
      path: "profile_settings",
      component: () => import("../app/admin/ProfileSettings.vue"),
      meta: {
         title: "Profile Settings",
      },
   },
];

export default admin_routes;
