import http from "../httpConfig";

class VModelService {
   createVModel(model) {
      return http
         .post("/vehicle/vmodel", model)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateVModel(model) {
      return http
         .put(`/vehicle/vmodel/${model._id}`, model)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getVModels(params) {
      return http
         .get("/vehicle/vmodel", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteVModel(model) {
      return http
         .delete(`/vehicle/vmodel/${model._id}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   selectedVModels(params) {
      return http
         .get("/vehicle/vmodel/selected", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new VModelService();
