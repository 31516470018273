import http from "../httpConfig";

class GenerationService {
   createGeneration(model) {
      return http
         .post("/vehicle/generation", model)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateGeneration(model) {
      return http
         .put(`/vehicle/generation/${model._id}`, model)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getGenerations(params) {
      return http
         .get("/vehicle/generation", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteGeneration(model) {
      return http
         .delete(`/vehicle/generation/${model._id}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   selectedGenerations(params) {
      return http
         .get("/vehicle/generation/selected", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new GenerationService();
