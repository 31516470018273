<template>
   <div class="preloader">
      <div class="loader_img">
         <img draggable="false" :src="require('../../assets/img/gif/pulse-loader.svg')" alt="loading..." height="150" width="150" />
      </div>
   </div>
</template>

<script>
export default {
   name: "Preloader",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common/theme.scss";

.preloader {
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   z-index: 100001;
   backface-visibility: hidden;
   //background: var(--bg-color-1);
  background: $darkBg;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.preloader .loader_img {
   width: 150px;
   height: 150px;
   background-position: center;
}
</style>
