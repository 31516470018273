import http from "../httpConfig";

class ShopOrderService {

  get_mine({ status, sort_by, sort_order, page, size }) {
    return http.get(`shop/order/get_mine?status=${status}&sort_by=${sort_by}&sort_order=${sort_order}&page=${page}&size=${size}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  get_all({ status, sort_by, sort_order, page, size }) {
    return http.get(`shop/order/get_all?status=${status}&sort_by=${sort_by}&sort_order=${sort_order}&page=${page}&size=${size}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  get_by_id({ id }) {
    return http.get(`shop/order/get_by_id/${id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  get_by_invoice_no({ invoice_no }) {
    return http.get(`shop/order/get_by_invoice_no/${invoice_no}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  mark_as_paid({ order_id, payment_method, txn_id }) {
    return http.post(`shop/order/mark_as_paid`, { order_id, payment_method, txn_id })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  mark_as_processing({ order_id }) {
    return http.post(`shop/order/mark_as_processing`, { order_id })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  mark_as_on_hold({ order_id, reason }) {
    return http.post(`shop/order/mark_as_on_hold`, { order_id, reason })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  mark_as_shipped({ order_id, parcel_tracking_number, estimated_delivery_date }) {
    return http.post(`shop/order/mark_as_shipped`, { order_id, parcel_tracking_number, estimated_delivery_date: Date.parse(estimated_delivery_date) })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  mark_as_completed({ order_id }) {
    return http.post(`shop/order/mark_as_completed`, { order_id })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  cancel({ order_id, reason }) {
    return http.post(`shop/order/cancel`, { order_id, reason })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

}

export default (new ShopOrderService());
