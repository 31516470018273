import http from "../httpConfig";

class TermsService {
   getTerms() {
      return http
         .get("/terms")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   addTerms(term) {
      return http
         .post("/terms", term)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateTerms(term) {
      return http
         .put("/terms/" + term._id, term)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteTerms(term) {
      return http
         .delete("/terms/" + term._id)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getTermsAndPolicy() {
      return http
         .get("/policies")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateTermsAndPolicy(data) {
      return http
         .post("/policies", data)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   addChooseText(data) {
      return http
         .post("/why_choose_us", data)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getChooseTexts() {
      return http
         .get("/why_choose_us")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateChooseText(option) {
      return http
         .put("/why_choose_us/" + option._id, option)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   async updateChooseTextPos(data) {
      await http
         .put(`/why_choose_us/${data._id}/update_pos`, data)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteChooseText(id) {
      return http
         .delete("/why_choose_us/" + id)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new TermsService();
