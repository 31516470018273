import http from "../httpConfig";

class BoschCodeService {
   createBoschCode(model) {
      return http
         .post("/bcode", model)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateBoschCode(model) {
      return http
         .put(`/bcode/${model._id}`, model)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getBoschCodesAdmin(params) {
      return http
         .get("/bcode_admin", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getBoschCodeSingle(params) {
      return http
         .get("/bcode_single", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteBoschCode(model) {
      return http
         .delete(`/bcode/${model._id}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new BoschCodeService();
