import http from "../httpConfig"

class VatService {

  getVats(search_keyword, page, size) {
    return http
      .get('/vat', {
        params: {
          search_keyword,
          page,
          size
        }
      }).then(res => Promise.resolve(res))
      .catch(err => Promise.reject(err))
  }

  getVatByCountry(country) {
    return http.get('/vat/' + country)
      .then(res => Promise.resolve(res))
      .catch(err => Promise.reject(err))
  }

  add(country, percentage = 0, enabled = true) {
    return http.post('/vat', { country, percentage, enabled })
      .then(r => Promise.resolve(r))
      .catch(r => Promise.reject(r))
  }

  toggle(id, enabled) {
    return http.put('/vat/toggle/' + id, { enabled })
      .then(r => Promise.resolve(r))
      .catch(r => Promise.reject(r))
  }

  changePercentage(id, percentage) {
    return http.put('/vat/percentage/' + id, { percentage })
      .then(r => Promise.resolve(r))
      .catch(r => Promise.reject(r))
  }

}

export default new VatService()
