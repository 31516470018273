import http from "../httpConfig";

class ServicesService {
   async createService(option) {
      await http
         .post("/service", option)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   async updateService(service) {
      await http
         .put(`/service/${service._id}`, service)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   async updateServicePos(data) {
      await http
         .put(`/service/${data._id}/update_pos`, data)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getServices(params) {
      return http
         .get("/service", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getAllServices() {
      return http
         .get("/service_all")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteService(service) {
      return http
         .delete(`/service/${service._id}`, service)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new ServicesService();
