import http from "../httpConfig";
class CreditService {
   getCredits(params) {
      return http
         .get("/credits", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getUserCredits(params) {
      return http
         .get(`/credits_user`, { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
   
   getUserTotalPurchasedCredits() {
      return http
         .get(`/credits/total-purchased`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getUserCreditsHistory(params) {
      return http
         .get(`/credits_history_user`, { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   addCredits(credit) {
      return http
         .post(`/credits_user`, credit)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   createEmptyInvoice(user_id) {
      return http
         .post('/credits/create-empty', { user_id })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   generateInvoice({ _id, item_name, amount, count, vat, user, status }) {
      return http
         .post('/credits/generate-invoice', {
            _id,
            item_name,
            amount,
            count,
            vat,
            user,
            status
         })
         .then((res) => {
            return Promise.resolve(res.data)
         })
         .catch((err) => {
            return Promise.reject(err)
         })
   }

   markAsPaid(credit_id) {
      return http
         .patch(`/credits/mark-as-paid`, { id: credit_id })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteCredit(credit) {
      return http
         .delete(`/credits/${credit._id}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   downloadInvoice(credit) {
      return http
         .get(`credits/${credit._id}/download_invoice`, { responseType: "blob" })
         .then((res) => {
            this.download(res.data, "invoice-" + credit.invoice + ".pdf");
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   download(data, fileName) {
      let blob = new Blob([data], {
         type: "application/pdf",
      });
      let downloadElement = document.createElement("a");
      let href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = fileName;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
   }
}

export default new CreditService();
